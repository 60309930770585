/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/** IE9, IE10 and IE11 requires all of the following polyfills. **/
// import 'core-js/es6/symbol';
// import 'core-js/es6/object';
// import 'core-js/es6/function';
// import 'core-js/es6/parse-int';
// import 'core-js/es6/parse-float';
// import 'core-js/es6/number';
// import 'core-js/es6/math';
// import 'core-js/es6/string';
// import 'core-js/es6/date';
// import 'core-js/es6/array';
// import 'core-js/es6/regexp';
// import 'core-js/es6/map';
// import 'core-js/es6/set';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js';  // Run `npm install --save classlist.js`.

/** IE10 and IE11 requires the following to support `@angular/animation`. */
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.

/** Evergreen browsers require these. **/
import "core-js/es/reflect"

/** ALL Firefox browsers require the following to support `@angular/animation`. **/
// import 'web-animations-js';  // Run `npm install --save web-animations-js`.
/***************************************************************************************************
 * Ensure that all modules which create observable like objects (rxjs(web-app/ts-lib), zen-observable(dependency of appolo client)) use the same version
 * of Symbol.observable
 * (https://github.com/ReactiveX/rxjs/blob/7.8.1/src/internal/symbol/observable.ts)
 * (NOTE: web-app and ts-lib use separate instances of rxjs)
 */
import "symbol-observable"

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import "zone.js" // Included with Angular CLI.
import "zone.js/plugins/zone-patch-rxjs" // zone-patch-rxjs is required for rxjs to be zone-aware
/***************************************************************************************************
 * APPLICATION IMPORTS
 */
/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
import "document-register-element"

// this is needed to make firefox properly deal with the "using" keyword
//@ts-expect-error(Suppress error "Attempt to assign to const or readonly variable")
// noinspection JSConstantReassignment
Symbol.dispose ??= Symbol("Symbol.dispose")
//@ts-expect-error(Suppress error "Attempt to assign to const or readonly variable")
// noinspection JSConstantReassignment
Symbol.asyncDispose ??= Symbol("Symbol.asyncDispose")
